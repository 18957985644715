import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'
import { graphql } from 'gatsby'
import LatestBlog from '../components/latest-blog'
import HomeSlide from '../components/homeslider'
//import FormInquire from '../components/form-inquire'
import Bar from '../images/bar-chart.png'
import Money from '../images/money.png'
// import Conversion from '../images/conversation.png'
import Collab from '../images/collaboration.png'
import Plan from '../images/planning.png'
import Testimonial from '../components/testimonial'

const IndexPage = ({ data }) => (
  <Layout head={{ title: 'Home' }}>
    <div id="hero-section">
      <HomeSlide />
      <div className="container zdex">
        <div className="slim-content">
          <h1>
            We Only Have One Mission:
            <span className="hero-highlighted d-block">Growing Your Sales</span>
          </h1>
          <h4 className="my-2 font-weight-bold">
            Sales Strategy / Sales Process / Sales Execution
          </h4>
          <p className="mt-5">
            Take a Free Sales Growth Assessment and get immediate
            recommendations on how to increase sales!
          </p>

          <div className="mt-4">
            <a
              className="btn btn-primary red-btn btn-lg"
              href="https://salesxceleration.com/take-sales-agility-assessment/?gf_advisor_slug=joe-dauskurdas"
              target="_blank"
              rel="noopener noreferrer"
            >
              FREE ASSESSMENT
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="services-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="section-heading">
              <span className="heading-span">Our Services</span>
              <h2>What We Can Do for You</h2>
            </div>
            <p className="my-4">
              A comprehensive suite of "Hands-on" services with one mission to
              rapidly grow your sales.
            </p>
            <Link className="btn btn-primary red-btn mb-5" to="/services">
              View All
            </Link>
          </div>
          <div className="col-md-9">
            <div className="services-slider">
              <Link to="/sales-growth-assessment" className="service-slide">
                <div>
                  <div className="service-img">
                    <img src={Plan} className="img-fluid" alt="planning" />
                  </div>
                  <h5>Sales Growth Assessment</h5>
                  <p>
                    Do you know why your sales are stagnant or declining? Do you
                    have clear insights and a plan to rapidly grow and scale
                    sales? We do!
                  </p>
                  <Link to="/sales-growth-assessment" className="red-link">
                    Learn More
                  </Link>
                </div>
              </Link>

              <Link
                to="/progrowth-rapid-growth-sales-plan"
                className="service-slide"
              >
                <div>
                  <div className="service-img">
                    <img
                      src={Collab}
                      className="img-fluid"
                      alt="collaboration"
                    />
                  </div>
                  <h5>ProGrowth Rapid Growth Sales Plan</h5>
                  <p>
                    Let's create a comprehensive plan that provides you with all
                    the tools you need to find and hire the right salespeople,
                    build the appropriate sales infrastructure and effectively
                    manage your sales team.
                  </p>
                  <Link
                    to="/progrowth-rapid-growth-sales-plan"
                    className="red-link"
                  >
                    Learn More
                  </Link>
                </div>
              </Link>

              <Link to="/outsourced-vp-of-sales" className="service-slide">
                <div>
                  <div className="service-img">
                    <img src={Bar} className="img-fluid" alt="bar" />
                  </div>
                  <h5>Outsourced / Fractional VP of Sales / CRO</h5>
                  <p>
                    You need to run your business; let our Sales Consultants run
                    your sales team. We create a high-impact sales culture to
                    maximize sales at a fraction of the cost of a full-time VP
                    of Sales.
                  </p>
                  <Link to="/outsourced-vp-of-sales" className="red-link">
                    Learn More
                  </Link>
                </div>
              </Link>

              <Link
                to="/certified-sales-mentoring-and-coaching"
                className="service-slide"
              >
                <div>
                  <div className="service-img">
                    <img src={Money} className="img-fluid" alt="money" />
                  </div>
                  <h5>Certified Sales Mentoring and Coaching</h5>
                  <p>
                    Certified Sales Mentoring and Coaching is a powerful 16-week
                    sales training and certification program that builds and
                    prepares sales teams for rapid growth.
                  </p>
                  <Link
                    to="/certified-sales-mentoring-and-coaching"
                    className="red-link"
                  >
                    Learn More
                  </Link>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="video-about">
      <div className="container">
        {/*
        <div className="homepage-video">
          <iframe
            title="Outsource Your VP of Sales | Get Results"
            width="560"
            height="630px"
            src="https://www.youtube.com/embed/fZwYad9kEL0"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true"
          />
        </div>
        */}
        <div className="text-center mt-4">
          <a href="/videos" className="btn red-btn">
            Watch Videos
          </a>
        </div>
        <div className="slim-content text-center pt-5">
          <div className="section-heading mt-5">
            <span className="heading-span">About Us</span>
            <h2>ProGrowth Advisors</h2>
          </div>
          <p className="lead mt-4">
            Joe Dauskurdas is the founder of ProGrowth Advisors and is a
            Certified Sales Leader focused on helping businesses achieve sales
            excellence. ProGrowth leverages the Sales Xceleration Platform to
            help clients exceed their growth and sales targets across a range of
            industries by building successful sales plans and highly productive
            sales teams.
          </p>
          <Link className="btn btn-primary red-btn mt-4" to="/about">
            Learn More
          </Link>
        </div>
      </div>
    </div>
    <div className="wpga text-center py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 align-self-center">
            <h2>Why ProGrowth Advisors?</h2>
          </div>
          <div className="col-md-6 align-self-center">
            <p>
              {/*<a
                href="https://www.salesxceleration.com/videos/?wvideo=n5n9jyzpnt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://embedwistia-a.akamaihd.net/deliveries/9c36d2d472a1a2ef1dd18258c0a067061ef48156.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=9f1e22e0"
                  className="img-fluid"
                  alt="Sales Xceleration"
                />
              </a>*/}
            </p>
            <p>
              <a
                href="https://www.salesxceleration.com/videos/?wvideo=n5n9jyzpnt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Videos | Sales Xceleration
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Testimonial />
    <div className="parallax-section">
      <div className="container">
        <div className="slim-content text-center text-white">
          <h2>Everything you need for the results you want.</h2>
          <a className="btn btn-primary red-btn mt-4 border" href="/contact-us">
            Get in touch
          </a>
        </div>
      </div>
    </div>
    {/* <div className="contact-section pb-5">
      <div className="container">
        <div className="contact-section-form slim-content">
          <div className="section-heading-light text-center">
            <span className="heading-span">
              Let Us KNow How We Can Help. Fill up the form below.
            </span>
          </div>
          <FormInquire />
        </div>
      </div>
    </div> */}
    <LatestBlog
      latestblog={data.allMarkdownRemark.edges.map(blog => blog.node)}
    />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            author
            banner
            shortdesc
            updatedDate
            cmsUserSlug
            categories
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
