import React from 'react'
import Swiper from 'react-id-swiper';
import Slide1 from '../../images/slide1.jpg'
import Slide2 from '../../images/slide2.jpg'
import Slide3 from '../../images/slide3.jpg'
import 'react-id-swiper/src/styles/css/swiper.css'


class HomeSlide extends React.Component {
  render() {
    const params = {
    	spaceBetween: 0,
      effect: 'fade',
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },
    }
 
    return(
      <Swiper {...params} containerClass="home-sliders">
        <div className="slide1 home-slider" style={{ background: `url("${ Slide1 } ")`}}></div>
        <div className="slide2 home-slider" style={{ background: `url("${ Slide2 } ")`}}></div>
        <div className="slide3 home-slider" style={{ background: `url("${ Slide3 } ")`}}></div>
      </Swiper>
    )
  }
}
 
export default HomeSlide;
