import React from 'react'
import { Link } from 'gatsby'

class LatestBlog extends React.Component {
  render() {
    // console.log(this.props.latestblog)

    return (
      <div className="blog-section">
        <div className="container">
          <div className="section-heading text-center">
            <span className="heading-span">From the Blog</span>
            <h2>Latest Insights</h2>
          </div>
          <div className="row">
            {this.props.latestblog.map(blog => (
              <div className="col-md-4 mx-auto">
                <div className="blogpost">
                  <Link to={blog.frontmatter.cmsUserSlug} className="blog-link">
                    <div className="blogpost-bg">
                      <div
                        className="blogpost-img"
                        style={{
                          backgroundImage: `url("${blog.frontmatter.banner}")`,
                        }}
                      />
                    </div>
                  </Link>
                  <div className="blogpost-text">
                    <Link
                      to={blog.frontmatter.cmsUserSlug}
                      className="blog-link"
                    >
                      <h5>
                        <strong>{blog.frontmatter.title}</strong>
                      </h5>
                    </Link>
                    <p>{blog.frontmatter.shortdesc}</p>
                    <Link
                      to={blog.frontmatter.cmsUserSlug}
                      className="red-link"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Link className="btn btn-primary red-btn mt-5" to="/blog">
            More Posts
          </Link>
        </div>
      </div>
    )
  }
}
// const LatestBlog =() =>(

//   <div className="blog-section">
//     <div className="container">
//       <div className="section-heading text-center"><span className="heading-span">From the Blog</span>
//       <h2>Latest Insights</h2>
//     </div>
//     <div className="row">
//       <div className="col-md-4">
//         <div className="blogpost">
//           <Link to="/test" className="blog-link">
//             <div className="blogpost-bg">
//               <div className="blogpost-img" style={{ background: `url("${ Blog1 } ")`}}></div>
//             </div>
//           </Link>
//           <div className="blogpost-text">
//             <Link to="/test" className="blog-link">
//               <h5><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit</strong></h5><span className="blogpost-date"><i className="fa fa-calendar-o"></i>February 05, 2019</span></Link>
//               <p>Lorem ipsum dolor sit amet, consectetur<br />adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p><Link to="/test" className="red-link">Read More</Link>
//           </div>
//         </div>
//       </div>
//       </div><Link className="btn btn-primary red-btn mt-5" to="/blog">More Posts</Link></div>
//     </div>

// )

export default LatestBlog
