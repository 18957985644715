import React from 'react'
import { Link } from 'gatsby'
import Test1 from '../../images/jason-crickmer.jpeg'
import Test2 from '../../images/gerardo-gada.jpeg'

class Testimonial extends React.Component {
  render() {
    return (
      <div className="testimonials-section text-center">
        <div className="container">
          <div className="section-heading-light text-center">
            <span className="heading-span">Testimonials</span>
            <h2>What Our Clients Say</h2>
          </div>
          <div className="row">
            <div className="col-md-6 my-5">
              <div className="testimonial-item">
                <img src={Test1} alt="testimonial-1" />
                <div className="testimonial-text">
                  <p>
                    As my partner running a SaaS business unit, I could not have
                    asked for a smarter and more dedicated sales leader than
                    Joe. To each customer and prospect relationship, Joe brought
                    organizational focus, passion for win-win outcomes, creative
                    problem-solving, and a selfless and humble perspective. Joe
                    organized, built, and managed a team of marketers, sales
                    professionals, and business development professionals - and
                    he helped them achieve remarkable results in a difficult
                    environment. I highly recommend Joe as an accomplished sales
                    executive and creative organizational leader.{' '}
                  </p>
                  <div className="testi-rank">
                    <h4>Jason Crickmer</h4>
                    <span className="testimonial-company">
                      Delivery and Customer Success Leader
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 my-5">
              <div className="testimonial-item">
                <img src={Test2} alt="testimonial-2" />
                <div className="testimonial-text">
                  <p>
                    Joe is a fantastic sales leader. His team trust him, he
                    understands customer needs, and he understands what is
                    needed to close sales. Joe is an asset to any B2B sales
                    team. I enjoyed working with Joe and would enjoy an
                    opportunity to work together again.
                  </p>
                  <div className="testi-rank">
                    <h4>Gerardo A Dada</h4>
                    <span className="testimonial-company">
                      Chief Marketing Officer, <em>DataCore Software</em>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 pt-5">
            <Link className="btn btn-primary red-btn mt-5" to="/testimonials">
              More Testimonials
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Testimonial
